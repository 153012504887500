import React, { useState } from 'react';

type CookiePreferences = {
	essential: boolean;
	analytics: boolean;
	marketing: boolean;
	personalization: boolean;
};

const CookieBanner: React.FC = () => {
	const [isBannerVisible, setBannerVisible] = useState(true);
	const [preferencesVisible, setPreferencesVisible] = useState(false);
	const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>({
		essential: true,
		analytics: true,
		marketing: true,
		personalization: false,
	});

	const handleAccept = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
		setBannerVisible(false);
	};

	const handleReject = () => {
		localStorage.setItem('cookiesAccepted', 'false');
		setBannerVisible(false);
	};

	const togglePreferences = () => {
		setPreferencesVisible(!preferencesVisible);
	};

	const handlePreferenceChange = (type: keyof CookiePreferences) => {
		setCookiePreferences({
			...cookiePreferences,
			[type]: !cookiePreferences[type],
		});
	};

	if (!isBannerVisible) return null;

	return (
		<>
			<div className="fixed z-50 text-sm left-1/2 bottom-4 -translate-x-1/2 text-white w-11/12 md:px-auto md:w-3/4 lg:w-auto">
				<div className="flex-col justify-center mx-auto backdrop-blur-lg bg-opacity-80 bg-primary rounded-xl">
					{renderContent()}
					{renderPreference()}
				</div>
			</div>
			{preferencesVisible && (
				<div onClick={togglePreferences}
				className='fixed w-full h-full bg-primary opacity-90 top-0 left-0'></div>
			)}
		</>
	);


	function renderContent() {
		if (preferencesVisible) return null;
		return (
			<div className="flex center align-center mx-auto px-4 py-4 justify-between text-xs">
				<div className="flex flex-col">
					<p>Mmmm Cookie...</p>
					<div onClick={togglePreferences}
						className="text-gray cursor-pointer">
						Personalizza
					</div>
				</div>
				<div className="flex space-x-2 ml-6">
					<button onClick={handleAccept}
						className="border-[#ccc] border py-1 px-2 rounded text-[#ccc] text-xs">
						ACCEPT
					</button>
					<button onClick={handleReject}
						className="border-[#838383] border py-1 px-2 rounded text-[#838383] text-xs">
						DENY
					</button>
				</div>
			</div>
		);
	}

	function renderPreference() {
		if (!preferencesVisible) return null;
		const preference = [
			{ t: "Essential", d: "These items are required to enable basic website functionality." },
			{ p: "marketing", t: "Marketing", d: "These items are used to track interest in our services and to retarget prospective customers with our own services." },
			{ p: "personalization", t: "Personalization", d: "These items allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features." },
			{ p: "analytics", t: "Analytics", d: "These items help us to monitor the performance and user behaviour on our website to improve the quality of our services and content." },
		]
		return (
			<div className="mt-4 bg-gray-700 p-4 rounded">
				<h4 className="text-lg font-semibold mb-9">Privacy Preference</h4>

				<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
					{preference.map((x: any, _i) => (
						<label className="inline-flex items-center justify-center cursor-pointer gap-4">
							<div className='flex flex-col gap-2 max-w-80'>
								<span className="text-lg">{x.t}</span>
								<span className="text-sm">{x.d}</span>
							</div>
							{x.p ? <input type="checkbox" className="sr-only peer"
								checked={(cookiePreferences as any)[x.p]}
								onChange={() => handlePreferenceChange(x.p)} /> : <span>Always Active</span>}
							{x.p && <div className="relative min-w-11 h-6 bg-gray peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>}
						</label>
					))}
				</div>

				<button onClick={handleAccept}
					className="bg-white px-4 py-2 rounded text-primary mt-11">
					Salva preferenze
				</button>
			</div>
		)
	}
};

export default CookieBanner;
