export const Dictionary = {

    HERO_TITLE: `Pensa in grande.<br/> Progetta velocemente.`,
    HERO_DESCRIPTION: "Abbonati ad un team professionale di design e sviluppo. Richieste illimitate, metti in pausa o annulla in qualsiasi momento.",

    CTA_PLAN: "Vedi i piani",
    AVAILABLE_TEXT: "Disponibile Ora",
    TEXT_OR: 'oppure',
    CTA_GET_IN_TOUCH: "prenota una chiamata",
    LINK_GET_IN_TOUCH: "https://calendly.com/trebarrasette/discover-onflow",
    LINK_TERMS: "https://trebarrasette.com/it/privacy",
    LINK_COOKIES: "https://trebarrasette.com/it/cookie",
    LINK_LOGIN: "mailto:onflow@trebarrasette.com?subject=Let's%20chat%20-%20Onflow&body=Ciao,%0A%0AHo%20letto%20del%20vostro%20servizio%20Onflow%20e%20sono%20davvero%20interessato!%20Mi%20piace%20molto%20l'idea%20di%20poter%20fare%20richieste%20illimitate%20di%20design%20e%20sviluppo,%20oltre%20alla%20flessibilità%20di%20mettere%20in%20pausa%20o%20annullare%20in%20qualsiasi%20momento.%0A%0AVorrei%20capire%20meglio%20come%20funziona%20il%20processo%20e%20quali%20tipi%20di%20progetti%20potete%20gestire.%20Potreste%20fornirmi%20ulteriori%20dettagli?%0A%0AGrazie%20mille%20per%20il%20supporto%20e%20attendo%20la%20vostra%20risposta!%0A%0ACordiali%20saluti, me too onflow",

    NAVIGATION: [
        {title: "Come funziona", link: "#how-it-works"},
        {title: "Vantaggi", link: "#benefit"},
        {title: "Servizi", link: "#pricing"},
        {title: "Prezzi", link: "#pricing"},
        {title: "Domande Frequenti", link: "#faq"},
    ],

    IMAGES_WORKS: [
        '/assets/carousel/items-01.png',
        '/assets/carousel/items-02.png',
        '/assets/carousel/items-03.png',
        '/assets/carousel/items-04.png'
    ],

    TEXT_BRANDS_LOGOS: "Scelto da centinaia di startup e agenzie in tutto il mondo",
    IMAGES_BRANDS_LOGOS: [
        '/assets/loghi/logo_01.svg',
        '/assets/loghi/logo_02.svg',
        '/assets/loghi/logo_03.svg',
        '/assets/loghi/logo_04.svg',
        '/assets/loghi/logo_05.svg',
        '/assets/loghi/logo_06.svg',
        '/assets/loghi/logo_07.svg',
        '/assets/loghi/logo_08.svg',
        '/assets/loghi/logo_09.svg',
        '/assets/loghi/logo_10.svg'
    ],

    ILLUSTRATION_TITLE: "Sviluppa tutti i tuoi progetti di design e <br/> web development",
    ILLUSTRATION_DESCRIPTION: "Sostituiamo agenzie troppo costose e freelance difficili da gestire. Con OnFlow avrai un canone fisso, un team sempre disponibile e richieste illimitate, un progetto alla volta.",
    ILLUSTRATION_BOX: [
        {
            IMAGE: '/assets/illustrations/icon-01.svg',
            TEXT: "Abbonati a un piano e inizia a richiedere i progetti."
        },
        {
            IMAGE: '/assets/illustrations/icon-02.svg',
            TEXT: `Ricevi il tuo progetto in media entro due giorni lavorativi*.`,
        },
        {
            IMAGE: '/assets/illustrations/icon-03.svg',
            TEXT: `Modifica finché non sarà al 100% come lo desideri.`,
        },
    ],

    TAGS: [
        {COLOR: '#ff49db', TEXT: 'Landing Page'},
        {COLOR: '#5281FB', TEXT: 'Loghi'},
        {COLOR: '#13ce66', TEXT: 'User Experience'},
        {COLOR: '#ffc82c', TEXT: 'Brand Guidline'},
        {COLOR: '#ff7849', TEXT: 'Icons'},
        {COLOR: '#7e5bef', TEXT: 'Brand Strategy'},
        {COLOR: '#1fb6ff', TEXT: 'User Interface'},
        {COLOR: '#FC909F', TEXT: 'Visual design'},
        {COLOR: '#2D604A', TEXT: 'Banner Adv'},
        {COLOR: '#CC61D5', TEXT: 'Media Social'},
        {COLOR: '#704CD8', TEXT: 'Front-end Developer'},
        {COLOR: '#0a0a0a', TEXT: '20+'},
    ],

    BENEFIT_TITLE: "member benefit",
    BENEFIT_DESCRIPTION: "Dimentica scadenze non rispettate, problemi di gestione, difficoltà di comunicazione, tariffe orarie, contratti complessi e prezzi troppo alti.",

    BENEFIT_CARDS: [
        {
            TITLE: "Tariffa mensile fissa",
            SUB_TITLE: "Pagherai ogni mese la stessa cifra.",
            ICON: "/assets/icons/food-drinks-coffee-mug--Streamline-Plump.svg"
        },
        {
            TITLE: "Richieste illimitate",
            SUB_TITLE: "Ottieni tutti i design di cui hai bisogno, senza limiti.",
            ICON: "/assets/icons/interface-arrows-synchronize--Streamline-Plump.svg"
        },
        {
            TITLE: "Designer di alto livello",
            SUB_TITLE: "Team altamente professionale, certificato da trebarrasette®.",
            ICON: "/assets/icons/travel-hotel-five-star--Streamline-Plump.svg"
        },
        {
            TITLE: "Tempi rapidi",
            SUB_TITLE: "In media, riceverai il tuo progetto uno alla volta in pochi giorni.",
            ICON: "/assets/icons/interface-calendar-check--Streamline-Plump.svg"
        },
        {
            TITLE: "Gestione semplice",
            SUB_TITLE: "Utilizziamo una board su Notion, metti in coda e ti avviseremo appena pronto.",
            ICON: "/assets/icons/mail-chat-two-bubbles-oval--Streamline-Plump.svg"
        },
        {
            TITLE: "Unico e tuo",
            SUB_TITLE: "Ognuno dei tuoi progetti è realizzato appositamente per te ed è tuo al 100%.",
            ICON: "/assets/icons/interface-edit-video-close-captioning--Streamline-Plump.svg"
        },
        {
            TITLE: "Design Quality",
            SUB_TITLE: "Design progettato da zero in base alle tue necessità, non siamo tipi da “template”.",
            ICON: "/assets/icons/interface-edit-vector-anchor-point--Streamline-Plump.svg"
        },
        {
            TITLE: "Flessibile",
            SUB_TITLE: "Metti in pausa o annulla in qualsiasi momento, hai tu il controllo.",
            ICON: "/assets/icons/interface-favorite-star-5--Streamline-Plump.svg"
        }
    ],


    JOINUS_TITLE: "Unisciti a OnFlow",
    JOINUS_TEXT: "di trebarrasette®",
    JOINUS_LINKS: [
        {
            TITLE: 'Prenota una Call introduttiva di 15 minuti',
            DESCRIPTION: 'Scopri di più su OnFlow e su come può aiutarti a far crescere il tuo business.',
            LINK: 'https://calendly.com/trebarrasette/discover-onflow'
        },
        {
            TITLE: 'Manda un messaggio',
            DESCRIPTION: 'Scrivici per saperne di più o chiarire i tuoi dubbi, ti risponderemo il prima possibile',
            LINK: "mailto:onflow@trebarrasette.com?subject=Richiesta%20informazioni%20su%20Onflow&body=Ciao,%0A%0AHo%20letto%20del%20vostro%20servizio%20Onflow%20e%20sono%20davvero%20interessato!%20Mi%20piace%20molto%20l'idea%20di%20poter%20fare%20richieste%20illimitate%20di%20design%20e%20sviluppo,%20oltre%20alla%20flessibilità%20di%20mettere%20in%20pausa%20o%20annullare%20in%20qualsiasi%20momento.%0A%0AVorrei%20capire%20meglio%20come%20funziona%20il%20processo%20e%20quali%20tipi%20di%20progetti%20potete%20gestire.%20Potreste%20fornirmi%20ulteriori%20dettagli?%0A%0AGrazie%20mille%20per%20il%20supporto%20e%20attendo%20la%20vostra%20risposta!%0A%0ACordiali%20saluti, me too onflow"
        },
        
    ],

    PAYMENT_TABLE: {
        STANDARD: {
            PAYMENT_TAG_TEXT: "Most Popular",
            PRICE: '€5.450',
            DESCRIPTION: "Una richiesta alla volta. Metti in pausa o annulla in qualsiasi momento.",
            LIST_TITLE: "Cosa è incluso nella membership",
            LINK_STRIPE: "mailto:onflow@trebarrasette.com?subject=Info Abbonamento - Piano Standard&body=Ciao Onflow Team! 🎉\n\nSono interessato ad abbonarmi al piano Standard e vorrei avere maggiori informazioni su come procedere. Non vedo l'ora di lavorare insieme e raggiungere risultati straordinari! Grazie! 🚀",
            LINK_TEXT_BUTTON: "Abbonati al piano Standard",
            LIST: [
                {TEXT: "Una richiesta alla volta"},
                {TEXT: "Metti in pausa quando vuoi"},
                {TEXT: "Senior designer e developer"},
                {TEXT: "Creatività per i social media"},
                {TEXT: "Design Illimitati"},
                {TEXT: "Dashboard & Design system"},
                {TEXT: "Website Webflow"},
                {TEXT: "Figma to Code conversion"},
                {TEXT: "Website front-end", TAG: 'NEW'},
                {TEXT: "Asset per Advertising"},
                {TEXT: "Foto Stock illimitate"},
                {TEXT: "Design presentazioni e pitch"},
                {TEXT: "UX/UI design"},
                {TEXT: "Website Wordpress", TAG: "BETA"},
            ]
        },
        PRO: {
            PAYMENT_TAG_TEXT: "Best Value",
            PRICE: '€9.850',
            DESCRIPTION: "Due richieste alla volta. Metti in pausa o annulla in qualsiasi momento.",
            LIST_TITLE: "Cosa è incluso nella membership",
            LINK_STRIPE: "mailto:onflow@trebarrasette.com?subject=Info Abbonamento - Piano Pro&body=Ciao Onflow Team! 🚀\n\nVorrei abbonarmi al piano Pro e avrei bisogno di alcune informazioni su come effettuare l'iscrizione. Sono entusiasta di iniziare questa avventura insieme! Grazie! ✨",
            LINK_TEXT_BUTTON: "Abbonati al piano ⚡️ Pro",
            LIST: [
                {TEXT: "Due richiesta alla volta"},
                {TEXT: "Metti in pausa quando vuoi"},
                {TEXT: "Senior designer e developer"},
                {TEXT: "Creatività per i social media"},
                {TEXT: "Design Illimitati"},
                {TEXT: "Dashboard & Design system"},
                {TEXT: "Website Webflow"},
                {TEXT: "Figma to Code conversion"},
                {TEXT: "Website front-end", TAG: 'NEW'},
                {TEXT: "Asset per Advertising"},
                {TEXT: "Foto Stock illimitate"},
                {TEXT: "Design presentazioni e pitch"},
                {TEXT: "UX/UI design"},
                {TEXT: "Website Wordpress", TAG: "BETA"},
            ]
        }
    },

    FAQ_TITLE: "Hai qualche domanda? Abbiamo raccolto quelle più frequenti. Se non trovi la risposta, <a href='mailto:onflow@trebarrasette.com'>scrivici</a>.",

    FAQ_LIST: [
        {
            TITLE: "Cosa ci distingue dalle altre agenzie?",
            DESCRIPTION: "Ci consideriamo un team multidisciplinare e modellabile in base alle tue richieste. Dalle nostre esperienze nelle grandi agenzie, abbiamo identificato i processi inutili che gonfiano i costi, complicano le comunicazioni e ritardano i tempi di consegna. Con Onflow, abbiamo snellito i servizi creativi includendo solo ciò che è essenziale per ottenere i migliori risultati per i nostri clienti. Offriamo servizi creativi di alta qualità che normalmente sarebbero fuori dalla portata delle aziende in crescita. Questo è ciò che ci distingue."
        },
        {
            TITLE: "Perché non assumere un designer interno o un freelance?",
            DESCRIPTION: "Nel mercato attuale, una figura creativa senior ti costerà cifre a cinque zeri, ci vorrà il tempo di selezione e di inserimento nel team interno, e poi la gestione. Con un abbonamento Onflow, hai accesso a talenti multidisciplinari di livello senior, e siamo noi a gestire tutto. Per quanto riguarda i freelance, i migliori sono spesso impegnati e quelli disponibili lavorano su più progetti contemporaneamente, causando spesso inconsistenze di brand."
        },
        {
            TITLE: "Le richieste creative sono davvero illimitate?",
            DESCRIPTION: "Una volta diventato cliente, puoi inviare tutte le richieste che desideri attraverso il tuo Project Workflow. Le elaboreremo una alla volta, in ordine di priorità da te definito. Ogni richiesta viene gestita singolarmente. Se desideri che più richieste vengano elaborate contemporaneamente, puoi semplicemente passare al nostro piano avanzato con più flussi di lavoro e creativi dedicati."
        },
        {
            TITLE: "Quanto tempo ci vuole per ricevere il mio lavoro?*",
            DESCRIPTION: "Dipende dal progetto richiesto. In media, completiamo la maggior parte delle richieste di design entro 2-3 giorni. Le richieste più complesse richiedono tempi più lunghi. Siamo sempre trasparenti e ragionevoli riguardo alle nostre stime di consegna e a eventuali fasi necessarie per raggiungere gli obiettivi."
        },
        {
            TITLE: "Qual è la vostra politica di rimborso?",
            DESCRIPTION: "Lavoriamo instancabilmente per rivedere, modificare e perfezionare il lavoro fino a quando non sarai soddisfatto. Considerando l'impegno, l'attenzione e la qualità che mettiamo in ogni progetto, non possiamo emettere rimborsi."
        },
        {
            TITLE: "E se avessi un singolo progetto?",
            DESCRIPTION: "I nostri abbonamenti funzionano al meglio per le aziende che hanno esigenze continue e una mole mensili sostenute. Se hai un singolo progetto, prenota una chiamata e adatteremo un servizio su misura per le tue esigenze specifiche."
        },
        {
            TITLE: "Cosa non facciamo?",
            DESCRIPTION: "La nostra gamma di servizi è completa, ma ci sono alcune aree che non rientrano nelle nostre competenze: l'acquisto di media, la scrittura tecnica e il 3D modeling sono alcune di queste. Se hai qualcosa di specifico in mente, contattaci e discutiamo i dettagli."
        },
        {
            TITLE: "Come posso inviare una richiesta creativa?",
            DESCRIPTION: "Dopo l'iscrizione, riceverai un'email che ti darà accesso alla tua dashboard cliente con un modulo di richiesta progetto che ti guiderà nei passaggi per avviare il lavoro. Una volta inviato, il tuo Project Manager dedicato ti contatterà entro 24 ore e assegnerà immediatamente il creativo appropriato. Riceverai aggiornamenti quotidiani sul progetto fino alla consegna finale. Semplice, facile e completo."
        },
        {
            TITLE: "Il lavoro sviluppato è di mia proprietà?",
            DESCRIPTION: "Ogni pezzo creativo che sviluppiamo è realizzato specificamente per il tuo brand ed è al 100% di tua proprietà. Condividiamo con te l'accesso a tutti i file sorgente."
        },
        {
            TITLE: "Come viene assemblato il mio team?",
            DESCRIPTION: "Il tuo Project Manager dedicato esaminerà il tuo flusso di lavoro previsto prima di assegnare il creativo appropriato, basandosi sulla personalità del brand e sulla natura e portata del lavoro. Per i progetti più complessi, il tuo creativo dedicato cambierà in base alla fase del progetto. Ad esempio, in un progetto di sviluppo web, il tuo creativo dedicato cambierà da strategist, copywriter, art director, designer, a sviluppatore, a seconda della fase di lavoro."
        },
        {
            TITLE: "Quanto tempo ci vuole per iniziare?",
            DESCRIPTION: "Immediatamente dopo l'iscrizione, riceverai un'email che ti darà accesso alla tua dashboard cliente con un modulo di richiesta progetto che notificherà il tuo Project Manager dedicato di assegnare il creativo. Dall'iscrizione all'inizio del lavoro passa meno di 24 ore."
        },
        {
            TITLE: "Come posso fare l'upgrade, downgrade o cancellare il mio abbonamento?",
            DESCRIPTION: "La scalabilità è semplice. Per qualsiasi modifica al tuo piano, basta inviare un'email con la richiesta di aggiornamento dello stato a onflow@trebarrasette.com I costi di upgrade vengono calcolati proporzionalmente durante il ciclo di fatturazione corrente. Per i downgrade, il tuo piano verrà rinnovato al livello inferiore alla prossima data di fatturazione. Per cancellare, basta inviare un'email a onflow@trebarrasette.com e ci occuperemo di tutto!"
        }
    ],
    
    PRE_FOOTER_TITLE: "Scopri se OnFlow è la scelta giusta per il tuo business",
    PRE_FOOTER_DESCRIPTION: "Partecipa a una visita guidata di OnFlow e scopri come puoi semplificare il tuo workflow, rivoluzionando il tuo modo di gestire i progetti di design e sviluppo.",
    TEXT_SEND_MAIL: "o invia una mail"

}