import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import './reveal.css';

gsap.registerPlugin(ScrollTrigger);

interface TextRevealProps {
  text: string;
}

const TextReveal: React.FC<TextRevealProps> = ({ text }) => {
  const refReveal = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const element = refReveal.current;
    if (!element) return;

   
    element.innerHTML = text;

   
    const split = new SplitType(element, {
      types: 'lines,words,chars',
    });

   
    split.lines!.forEach((line) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('split-line');
      line.parentNode!.insertBefore(wrapper, line);
      wrapper.appendChild(line);
    });
   
    const animation = gsap.to(split.lines, {
      scrollTrigger: {
        trigger: element,
        toggleActions: 'restart pause resume reverse',
        start: '50% 100%',
        markers: false,
        invalidateOnRefresh: true,
      },
      duration: .85,
      ease: 'circ.out',
      yPercent: -100,
      stagger: 0.2,
    });

   
    return () => {
      animation.kill();
      split.revert();
      ScrollTrigger.refresh();
    };
  }, [text]);

  return <span ref={refReveal}></span>;
};

export default TextReveal;
