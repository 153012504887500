import React from 'react'
import { Dictionary } from '../../constants/content'

const Footer = () => {

    return (
        <div className="w-full bg-primary pt-16 pb-9">
            <div className='w-full px-5 max-w-7xl mx-auto flex flex-col gap-8 lg:flex-row justify-between items-center'>
                <img className='max-w-48' src={`${process.env.PUBLIC_URL}/logo-white.svg`} alt="logo onflow" />
                <div className='flex gap-5 flex-wrap items-center justify-center'>
                    {Dictionary.NAVIGATION.map((x, i) => (
                        <a key={i} className='text-white hover:underline'
                            href={x.link} rel="noopener noreferrer">
                            {x.title}
                        </a>
                    ))}
                </div>
                <div className="terms flex gap-5">
                <a className='text-white hover:underline'
                    href={Dictionary.LINK_TERMS} rel="noopener noreferrer" target='_blank'>
                    Termini
                </a>
                <a className='text-white hover:underline'
                    href={Dictionary.LINK_COOKIES} rel="noopener noreferrer" target='_blank'>
                    Cookies
                </a>
                </div>
            </div>
        </div>
    )
}

export default Footer